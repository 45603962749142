import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: {
    open: false,
    drilldownKey: null, // 'property'
    queryFilters: [],
    dateSelection: null,
    meta: null
  },
  filters: {
    property: null,
    service: null,
    dateRange: [null, null]
  },
  queryFilters: []
};

const slice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    setFilters(state, action) {
      const { filterName, value } = action.payload;
      state.filters[filterName] = value;
      state.queryFilters = [
        state.filters.property?.length > 0
          ? {
              member: 'Properties.propertyUid',
              operator: 'equals',
              values: state.filters.property?.map((p) => p.uid)
            }
          : undefined,
        state.filters.service?.length > 0
          ? {
              member: 'Services.serviceId',
              operator: 'equals',
              values: state.filters.service?.map((s) => s.id.toString())
            }
          : undefined
      ].filter(Boolean);
    },
    clearFilters(state, action) {
      state.filters = initialState.filters;
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isDrawerOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state, action) {
      const {
        drilldownKey,
        queryFilters = initialState.modal.queryFilters,
        dateSelection = initialState.modal.dateSelection,
        meta = initialState.modal.meta
      } = action.payload;

      state.modal.open = true;
      state.modal.drilldownKey = drilldownKey;
      state.modal.queryFilters = queryFilters;
      state.modal.dateSelection = dateSelection;
      state.modal.meta = meta;
    },
    closeModal(state, action) {
      state.modal.open = false;
      // setTimeout(() => (state.modal = initialState.modal), 300);
    }
  }
});

export const reducer = slice.reducer;

export const setFilters = (filterName, value) => async (dispatch) => {
  dispatch(
    slice.actions.setFilters({
      filterName,
      value
    })
  );
};

export const clearFilters = () => (dispatch) => {
  dispatch(slice.actions.clearFilters());
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export const openModal = ({
  drilldownKey,
  queryFilters,
  dateSelection,
  meta
}) => (dispatch) => {
  dispatch(
    slice.actions.openModal({
      drilldownKey,
      queryFilters,
      dateSelection,
      meta
    })
  );
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
