import React, { useState } from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider
} from '@mui/material';
import { themeCreator } from './base';
import StylesProvider from '@mui/styles/StylesProvider';

export const ThemeContext = React.createContext((themeName) => {});

const ThemeProvider = function (props) {
  const curThemeName = localStorage.getItem('appTheme') || 'GreyGooseTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName) => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
        </StyledEngineProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProvider;
