import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import api from 'src/services/api';

const initialState = {
  isMailLoading: true,
  categoryFilter: 'unscheduled', // unscheduled, scheduled, archived
  mails: [],
  filteredMails: [],
  notification: {
    open: false,
    message: '',
    variant: 'success'
  },
  selectedMail: null,
  tags: [
    {
      id: 'unscheduled',
      type: 'category_tag',
      name: 'Unscheduled'
    },
    {
      id: 'scheduled',
      type: 'category_tag',
      name: 'Scheduled'
    },
    {
      id: 'archived',
      type: 'category_tag',
      name: 'Archived'
    }
  ],
  mailboxCounts: {
    unscheduled: null,
    scheduled: null,
    archived: null
  }
};

function cleanMails(mails) {
  return mails.map((mail) => {
    const fromName = mail.from?.match(/\"(.*?)\"/);
    const fromEmail = mail.from?.match(/<(.*?)>/);
    const toName = mail.to?.match(/\"(.*?)\"/);
    const toEmail = mail.to?.match(/<(.*?)>/);

    return {
      ...mail,
      summary:
        mail.bodyPreview?.substring(0, 100) || mail.body?.substring(0, 100),
      from: {
        name: fromName?.[1],
        email: fromEmail?.[1] || mail.from
      },
      to: {
        name: toName?.[1],
        email: toEmail?.[1] || mail.to
      },
      // Clean Subject
      subject: mail.subject.replace(/^forward\: |fw\: |fwd\: |fwrd\: /gim, '')
      //
    };
  });
}

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    setMailLoading: (state, action) => {
      state.isMailLoading = action.payload;
    },
    getTags(state, action) {
      const { tags } = action.payload;

      state.tags = tags;
    },
    getMails(state, action) {
      const { mails, counts } = action.payload;
      // Set Counts
      // find object in counts array of objects by status code (options of 's', 'a', 'u')
      state.mailboxCounts.unscheduled = counts.find(
        (count) => count.statusCode.toLowerCase() === 'u'
      ).count;
      state.mailboxCounts.scheduled = counts.find(
        (count) => count.statusCode.toLowerCase() === 's'
      ).count;
      state.mailboxCounts.archived = counts.find(
        (count) => count.statusCode.toLowerCase() === 'a'
      ).count;

      state.mails = mails;
      state.isMailLoading = false;
    },
    getMail(state, action) {
      const { mail } = action.payload;

      state.selectedMail = mail;
    },
    changeMailStatus(state, action) {
      const { uid, statusCode } = action.payload;
      // Remove mail from list
      const newMailsList = state.mails.filter((mail) => mail.inboxUID !== uid);

      state.mails = newMailsList;
      state.mailboxCounts = {
        ...state.mailboxCounts,
        ...(statusCode === 'U'
          ? {
              unscheduled: state.mailboxCounts.unscheduled + 1,
              archived: state.mailboxCounts.archived - 1
            }
          : {}),
        ...(statusCode === 'A'
          ? {
              unscheduled: state.mailboxCounts.unscheduled - 1,
              archived: state.mailboxCounts.archived + 1
            }
          : {})
      };
    },
    resetMailNotification(state, action) {
      state.notification = initialState.notification;
    },
    setMailNotification(state, action) {
      const { open, message, variant } = action.payload;
      state.notification.open = open;
      state.notification.message = message;
      state.notification.variant = variant;
    },
    setCategoryFilter(state, action) {
      const { category } = action.payload;

      state.categoryFilter = category;
    }
  }
});

export const reducer = slice.reducer;

export const getTags = () => async (dispatch) => {
  try {
    // const response = await axios.get('/api/mailbox/tags');
    // dispatch(slice.actions.getTags(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const getMails = (params) => async (dispatch) => {
  dispatch(slice.actions.setMailLoading(true));
  const categoryTag = params?.categoryTag?.toLowerCase();
  let status;
  if (Boolean(categoryTag)) {
    switch (categoryTag) {
      case 'unscheduled':
        status = 'u';
        break;
      case 'scheduled':
        status = 's';
        break;
      case 'archived':
        status = 'a';
        break;
    }
  }

  const response = await api.get('/webapp/get-inbox-list', {
    params: {
      status
    }
  });

  const { inbox, statusCount } = response.data.result;

  dispatch(
    slice.actions.getMails({
      mails: inbox ? cleanMails(inbox) : [],
      counts: statusCount
    })
  );
};

export const setCategoryFilter = (category) => (dispatch) => {
  dispatch(slice.actions.setCategoryFilter(category));
};

export const getMail = (inboxuid) => async (dispatch) => {
  const response = await api.get('/webapp/get-inbox-item', {
    params: {
      inboxuid
    }
  });

  dispatch(
    slice.actions.getMail({
      mail: cleanMails([response.data.result])[0]
    })
  );
};

export const updateMailStatus =
  ({ uid, uids, statusCode }) =>
  async (dispatch) => {
    if (!uids && !uid) return console.error('No uid or uids provided');
    try {
      await api.put('/webapp/update-inbox-item-status', {
        inboxUID: uids || [uid],
        status: statusCode
      });
    } catch (error) {
      console.error(response.data.result);
    } finally {
      dispatch(slice.actions.changeMailStatus({ uid, statusCode }));
      dispatch(
        slice.actions.setMailNotification({
          open: true,
          message: statusCode === 'A' ? 'Mail Archived' : 'Mail Unarchived',
          variant: 'success'
        })
      );
      dispatch(slice.actions.resetMailNotification());
    }
  };

export default slice;
