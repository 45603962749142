export const WORK_TYPES = [
  {
    id: 1,
    name: 'Complaint'
  },
  {
    id: 2,
    name: 'Request'
  },
  {
    id: 3,
    name: 'Scheduled Maintenance'
  },
  {
    id: 4,
    name: 'Reported Issue'
  }
];
