import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import routes, { renderRoutes } from './router';
import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ScrollToTop from './utils/ScrollToTop';

import { AuthProvider } from './contexts/JWTAuthContext';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import HotJar from './components/Tracking/HotJar';
import { IKContext } from 'imagekitio-react';
import appConfig from './app.config';

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

function App() {
  console.info(`Current Version: ${appConfig.version}`);
  return (
    <ThemeProvider>
      <IKContext
        publicKey={appConfig.ikPublicKey}
        urlEndpoint={appConfig.ikEndpoint}
        transformationPosition="path"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Router history={history}>
              <ScrollToTop />
              <CssBaseline />
              <HotJar />
              <AuthProvider>{renderRoutes(routes)}</AuthProvider>
            </Router>
          </SnackbarProvider>
        </LocalizationProvider>
      </IKContext>
    </ThemeProvider>
  );
}
export default App;
