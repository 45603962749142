import axios from 'axios';
import TokenService from './tokens';
import { baseURL } from 'src/services/apiConfig';

const instance = axios.create({
  baseURL
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    let retry = originalConfig._retry;
    // If Access Token was expired, try to refresh it
    if (
      originalConfig.url !== '/authenticate/GetClaims' &&
      err.response?.status === 401 &&
      !retry
    ) {
      // Only allow one retry
      retry = true;

      try {
        const response = await instance.post(
          '/authenticate/refresh-token',
          null,
          {
            params: {
              token: TokenService.getLocalRefreshToken()
            }
          }
        );

        const { jwtToken: accessToken, refreshToken } = response.data.result;

        TokenService.setLocalAccessToken(accessToken);
        TokenService.setLocalRefreshToken(refreshToken);

        return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    } else {
      // Rewrite API error shape to make it more accessible within the application code
      return Promise.reject({
        ...err,
        message:
          err.response?.data?.responseException?.exceptionMessage || err.message
      });
    }
  }
);

export default instance;
