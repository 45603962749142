import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as insightsReducer } from 'src/slices/insights';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as worklogsReducer } from 'src/slices/worklogs';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  user: userReducer,
  mailbox: mailboxReducer,
  insights: insightsReducer,
  worklogs: worklogsReducer
});

export default rootReducer;
