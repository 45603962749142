import React from 'react';
import {
  Container,
  Box,
  Grid,
  Card,
  Typography,
  List,
  ListItem
} from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const FeatureTitle = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(36)};
    line-height: 1.725;
`
);

const TypographyH2 = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
    line-height: 1.725;
`
);

const BlowWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    position: relative;
    margin-top: ${theme.spacing(5)};
`
);

const Blob1 = experimentalStyled(Box)(
  ({ theme }) => `
  background: ${theme.colors.alpha.black[70]};
  width: 260px;
    height: 260px;
    position: absolute;
    z-index: 5;
    top: -${theme.spacing(9)};
    right: -${theme.spacing(7)};
    border-radius: 30% 70% 82% 18% / 26% 22% 78% 74%;
`
);

const Blob2 = experimentalStyled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[100]};
    width: 140px;
    bottom: -${theme.spacing(5)};
    left: -${theme.spacing(5)};
    position: absolute;
    z-index: 5;
    height: 140px;
    border-radius: 77% 23% 30% 70% / 81% 47% 53% 19% ;
`
);

const CondensedGridContainer = experimentalStyled(Grid)(
  () => `
    margin-top: -195px;
    margin-bottom: -170px;
`
);

const CardImg = experimentalStyled(Card)(
  ({ theme }) => `
    position: absolute;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const Feature = experimentalStyled(Box)(
  ({ theme }) => `
    width: 100%;
    background: #fff;
    padding-top: 100px;
    padding-bottom: 100px;
`
);

const Screenshot = experimentalStyled('img')(
  ({ theme }) => `
    width: 100%;
    height: auto;
`
);

const ContentGrid = experimentalStyled(Grid)(
  ({ theme }) => `
    width: 100%;
    height: auto;
`
);

ContentGrid;

const Features = (props) => {
  const { t } = useTranslation();
  return (
    <Box {...props}>
      <Feature sx={{ background: '#fff' }}>
        <Container maxWidth="lg" sx={{ py: 0 }}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} md={6}>
              <BlowWrapper>
                <Screenshot
                  src="/static/images/overview/screenshot_1.png"
                  width="100%"
                />
              </BlowWrapper>
            </Grid>
            <ContentGrid item xs={12} md={6} px={10}>
              <FeatureTitle variant="h2">{t('Work Logs')}</FeatureTitle>
              <TypographyH2
                sx={{ my: 2 }}
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
              >
                {t(
                  `Receive photo proof of all the important work your vendors are completing across your entire portfolio of properties.`
                )}
                {/* Easily browse through all activities at your properties and view proof that work was completed. No more checking texts, emails, WhatsApp to see if something was done. */}
                {/* t(
                  `Awareness - without the need to micro-manage. You'll receive photo proof of completed work and be able to search.`
                ) */}
              </TypographyH2>
            </ContentGrid>
          </Grid>
        </Container>
      </Feature>
      <Feature sx={{ background: '#FBFBFC' }}>
        <Container maxWidth="lg" sx={{ py: 0 }}>
          <CondensedGridContainer container spacing={0} alignItems="center">
            <ContentGrid item xs={12} md={6} px={10}>
              <FeatureTitle variant="h2">
                {t('Properties Explorer')}
              </FeatureTitle>
              <TypographyH2
                sx={{ my: 2 }}
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
              >
                {t(
                  'For each property, view your vendors, their contracts or COIs, and the work activity stats.'
                )}
              </TypographyH2>
            </ContentGrid>
            <Grid item xs={12} md={6}>
              <BlowWrapper>
                <Screenshot
                  src="/static/images/overview/properties_explorer.png"
                  width="100%"
                />
              </BlowWrapper>
            </Grid>
          </CondensedGridContainer>
        </Container>
      </Feature>
      <Feature sx={{ background: '#ffffff' }}>
        <Container maxWidth="lg" sx={{ py: 0 }}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} md={6}>
              <BlowWrapper>
                <Screenshot
                  src="/static/images/overview/email_notification.png"
                  width="100%"
                />
              </BlowWrapper>
            </Grid>
            <ContentGrid item xs={12} md={6} px={10}>
              <FeatureTitle variant="h2">
                {t('Email Notifications')}
              </FeatureTitle>
              <TypographyH2
                sx={{ my: 2 }}
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
              >
                {t(
                  'Receive an automatic notice whenever a vendor completes work at one of your properties (or just specific vendors).'
                )}
              </TypographyH2>
            </ContentGrid>
          </Grid>
        </Container>
      </Feature>
    </Box>
  );
};

export default Features;
