import { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'src/store';
import { getProperties } from 'src/slices/user';

import { experimentalStyled } from '@mui/material/styles';
import { Autocomplete, Popper, Avatar, TextField, Chip } from '@mui/material';
import { IKImage, IKContext } from 'imagekitio-react';
import {
  makeUrlEndpoint,
  transformImagePath
} from 'src/utils/transformImagePath';

const OptionListItem = experimentalStyled('li')(
  ({ theme }) => `
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`
);

const CustomPopper = experimentalStyled(Popper)(
  ({ theme }) => `
    width: fit-content !important;
`
);

const CustomPopperWithProps = (props) => (
  <CustomPopper {...props} placement="bottom-start" />
);

function getOptionLabel(option) {
  if (!option) return 'uknown property';
  if (option.name) return option.name;
  if (option.address?.line1) return option.address.line1;
  if (option.uid) return option.uid;
  return 'unknown property';
}

const makeRenderInput =
  ({ inputErrorText, value, multiSelect = false }) =>
  (params) => {
    const { t } = useTranslation();
    const image = transformImagePath({
      splitContainer: true,
      path: value?.thumbnailUrl,
      containerKey: 'properties'
    });
    return (
      <div
        style={{
          position: 'relative'
        }}
      >
        {!multiSelect && (
          <Avatar
            variant="rounded"
            sx={{
              mr: 1,
              position: 'absolute',
              top: 7,
              left: 7
            }}
          >
            {value ? (
              <IKImage
                urlEndpoint={image.urlEndpoint}
                path={image.path}
                transformation={[
                  {
                    height: 100,
                    width: 100,
                    crop: 'force'
                  }
                ]}
                style={{
                  width: '100%'
                }}
              />
            ) : (
              <img
                src={image.fallback}
                style={{ width: 145, height: 'auto' }}
              />
            )}
          </Avatar>
        )}
        <TextField
          {...params}
          name="property"
          error={!!inputErrorText}
          helperText={inputErrorText}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              paddingLeft: !multiSelect ? '48px' : '0px',
              fontWeight: 600
            }
          }}
          label={multiSelect ? t('Properties') : t('Property')}
          placeholder={
            multiSelect ? t('Select properties...') : t('Select property...')
          }
        />
      </div>
    );
  };

export default function SelectProperty({
  value,
  multiSelect = false,
  handleChange = (_event, _value) => {},
  ...rest
}) {
  const dispatch = useDispatch();
  const { properties } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  return (
    <Autocomplete
      multiple={multiSelect}
      limitTags={multiSelect ? 1 : 10}
      value={value}
      options={properties.data}
      loading={properties.loading}
      PopperComponent={CustomPopperWithProps}
      onChange={handleChange}
      renderOption={(optionProps, option) => {
        const image = transformImagePath({
          splitContainer: true,
          path: option.thumbnailUrl,
          containerKey: 'properties',
          transformation: 'tr=w-600,h-250'
        });
        return (
          <OptionListItem {...optionProps}>
            <Avatar variant="rounded" sx={{ mr: 1 }}>
              <IKImage
                urlEndpoint={image.urlEndpoint}
                path={image.path}
                transformation={[
                  {
                    height: 100,
                    width: 100,
                    crop: 'force'
                  }
                ]}
                style={{
                  width: '100%'
                }}
              />
            </Avatar>
            <b>{option.name}</b>
            &nbsp;{option.address?.line1}
          </OptionListItem>
        );
      }}
      isOptionEqualToValue={(option, value) => option.uid === value.uid}
      getOptionLabel={getOptionLabel}
      renderInput={makeRenderInput({ value, multiSelect, ...rest })}
      renderTags={(properties, getTagProps) =>
        properties.map((property, index) => {
          const chipImageUrl = transformImagePath({
            fullUrl: true,
            path: property.thumbnailUrl,
            containerKey: 'properties',
            transformation: 'tr=w-600,h-250'
          });
          return (
            <Chip
              label={property.name}
              {...getTagProps({ index })}
              avatar={<Avatar src={chipImageUrl} />}
            />
          );
        })
      }
      {...rest}
    />
  );
}
