import React, { useRef, useState } from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

// import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

function HeaderMenu() {
  const { t } = useTranslation();

  // const ref = useRef(null);
  // const [isOpen, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <List
        sx={{ py: 2, gap: 1, pl: { xs: 0, md: 2 } }}
        component={Box}
        display="flex"
      >
        <ListItem
          classes={{ root: 'MuiListItem-indicators' }}
          button
          smooth
          component={NavHashLink}
          to="#features"
          // activeStyle={{ background: 'white' }}
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={t('Features')}
          />
        </ListItem>
        <ListItem
          classes={{ root: 'MuiListItem-indicators' }}
          button
          smooth
          component={NavHashLink}
          to="#how-it-works"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={t('How It Works')}
          />
        </ListItem>
        <ListItem
          classes={{ root: 'MuiListItem-indicators' }}
          button
          smooth
          component={NavHashLink}
          to="#industries"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={t('Industries')}
          />
        </ListItem>
      </List>
    </>
  );
}

export default HeaderMenu;
