import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import api from 'src/services/api';

const initialState = {
  events: [],
  filteredEvents: [],
  filterNoMatches: false,
  isEditingSeries: false,
  isDrawerOpen: false,
  selectedEventId: null,
  selectedRange: null,
  filters: {
    text: '',
    property: null,
    service: null
  },
  popover: {
    open: false,
    event: null,
    anchorEl: null
  },
  schedulingInboxId: null
};

const filterEvents = (events, filters) => {
  try {
    if (!events || events.length == 0) return null;

    return events.filter((event) => {
      let matches = true;

      if (filters.property && event.property?.uid !== filters.property.uid) {
        matches = false;
      }

      if (filters.service && event.vendor?.service?.id !== filters.service.id) {
        matches = false;
      }

      if (filters.text.length > 0) {
        const normalizedQuery = filters?.text?.toLowerCase();

        // Check event ID
        const eventId = event?.id?.toLowerCase();
        const eventTitle = event?.title?.toLowerCase();

        // Check Property Matching
        const propertyName = event?.property?.name?.toLowerCase();
        const propertyShortAddress = event?.property?.shortAddress?.toLowerCase();
        const propertyAddressLine1 = event?.property?.address?.line1?.toLowerCase();

        // Check Vendor Matching
        const vendorName = event?.vendor?.name?.toLowerCase();
        const vendorService = event?.vendor?.service?.name?.toLowerCase();

        if (
          !eventId?.includes(normalizedQuery) &&
          !eventTitle?.includes(normalizedQuery) &&
          !propertyName?.includes(normalizedQuery) &&
          !propertyShortAddress?.includes(normalizedQuery) &&
          !propertyAddressLine1?.includes(normalizedQuery) &&
          !vendorName?.includes(normalizedQuery) &&
          !vendorService?.includes(normalizedQuery)
        ) {
          matches = false;
        }
      }
      return matches;
    });
  } catch (error) {
    console.error(error);
  }
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      const { events } = action.payload;
      state.events = events;
    },
    setFilters(state, action) {
      const { filterName, value } = action.payload;
      state.filters[filterName] = value;
    },
    filterEvents(state, action) {
      const filteredEvents = filterEvents(
        action.payload?.events || state.events,
        state.filters
      );
      state.filteredEvents = filteredEvents;
      state.filterNoMatches = filteredEvents?.length == 0;
    },
    clearFilters(state, action) {
      state.filters = {
        text: '',
        property: null,
        service: null
      };
      state.filteredEvents = state.events;
      state.filterNoMatches = false;
    },
    createEvent(state, action) {
      const { event } = action.payload;

      // state.events = [...state.events, event];
    },
    selectEvent(state, action) {
      const { eventId = null, editingSeries = false } = action.payload;

      state.isDrawerOpen = true;
      state.selectedEventId = eventId;
      state.isEditingSeries = editingSeries;
    },
    updateEvent(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isDrawerOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openDrawerPanel(state) {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state) {
      state.isDrawerOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
      state.isEditingSeries = false;
    },
    openPopover(state, action) {
      const { event, anchorEl } = action.payload;

      state.popover.open = true;
      state.popover.event = event;
      state.popover.anchorEl = anchorEl;
    },
    closePopover(state) {
      state.popover.open = false;
      state.popover.event = null;
      state.popover.anchorEl = null;
    },
    setSchedulingInboxId(state, action) {
      const { inboxId } = action.payload;
      state.schedulingInboxId = inboxId;
    }
  }
});

export const reducer = slice.reducer;

export const getEvents = (month, year) => async (dispatch) => {
  if (!month || !year) throw Error('Month & Year must be provided.');
  const response = await api.get(`/webapp/calendar/events/${month}/${year}`);
  const remappedResponse = {
    events: response.data.result?.map((event) => ({
      // ...event,
      id: event.eventUID,
      scheduleUID: event.scheduleUID,
      eventUID: event.eventUID,
      allDay: false,
      title: event.title,
      start: event.start,
      end: event.end,
      eventType: event.eventType,
      occurrence: event.occurrence,
      color: '#152359',
      property: event.property,
      vendor: event.vendor,
      // creating new namespace for "extendedProps" (Full Calendar spec)
      // because allDay true flag will set default start as midnight
      timing: {
        start: event.start,
        end: event.end
      }
    }))
  };
  dispatch(slice.actions.getEvents(remappedResponse));
  dispatch(slice.actions.filterEvents(remappedResponse));
};

export const createEvent = (data) => async (dispatch) => {
  const response = await api.post('/webapp/calendar/create-schedule', data);
  dispatch(slice.actions.createEvent(response.data));
};

export const selectEvent = (eventId, editingSeries) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId, editingSeries }));
};

export const updateEvent = (update) => async (dispatch) => {
  await api.put('/webapp/calendar/reschedule-event', {
    eventUID: update.eventUID,
    startDateTime: update.start,
    endDateTime: update.end
  });
  // dispatch(slice.actions.updateEvent(response.data));
};

export const updateSeries = (update) => async (dispatch) => {
  const response = await api.put('/webapp/calendar/update-schedule', update);
  // dispatch(slice.actions.updateEvent(response.data));
};

export const deleteEvent = (eventId) => async (dispatch) => {
  const response = await api.put('/webapp/calendar/cancel-event', null, {
    params: {
      eventUID: eventId
    }
  });

  // dispatch(slice.actions.deleteEvent({ eventId }));
};

export const deleteSeries = (scheduleId) => async (dispatch) => {
  const response = await api.put('/webapp/calendar/cancel-schedule', null, {
    params: {
      scheduleUID: scheduleId
    }
  });

  // dispatch(slice.actions.deleteEvent({ eventId }));
};

export const setFilters = (filterName, value) => async (dispatch) => {
  dispatch(
    slice.actions.setFilters({
      filterName,
      value
    })
  );
  dispatch(slice.actions.filterEvents());
};

export const clearFilters = () => (dispatch) => {
  dispatch(slice.actions.clearFilters());
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export const openDrawerPanel = () => (dispatch) => {
  dispatch(slice.actions.openDrawerPanel());
};

export const closeDrawerPanel = () => (dispatch) => {
  dispatch(slice.actions.closeDrawerPanel());
};

export const openPopover = (popover) => (dispatch) => {
  dispatch(slice.actions.openPopover(popover));
};

export const closePopover = () => (dispatch) => {
  dispatch(slice.actions.closePopover());
};

export const setSchedulingInboxId = ({ inboxId }) => (dispatch) => {
  dispatch(slice.actions.setSchedulingInboxId({ inboxId }));
};

export default slice;
