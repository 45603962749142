import { Box, Card, Container, Typography } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';

const FooterWrapper = experimentalStyled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(3)};
`
);

function Footer() {
  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Box
          py={3}
          display={{ xs: 'block', md: 'flex' }}
          alignItems="center"
          textAlign={{ xs: 'center', md: 'left' }}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="subtitle1">
              &copy; 2021 - Partners Operating Platform, Inc.
            </Typography>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
