import React from 'react';
import {
  Container,
  Box,
  Grid,
  Card,
  Typography,
  List,
  ListItem
} from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const HowItWorksWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    background: #fff;
    width: 100%;
`
);

const Title = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(36)};
    line-height: 1.725;
`
);

const MobileMockup = experimentalStyled('img')(
  ({ theme }) => `
    max-height: 850px;
    width: auto;
    float: right;
    margin-right: 45px;
`
);

const ContentGrid = experimentalStyled(Grid)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
    line-height: 1.725;
`
);

const StepWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 65px;
`
);

const StepNumber = experimentalStyled(Box)(
  ({ theme }) => `
    border: 5px solid #ECEEF2;
    border-radius: 50%;
    height: 70px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    font-weight: 800;
    color: #636F86;
    font-size: ${theme.typography.pxToRem(32)};
    margin-right: 30px;
`
);

const StepText = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: 18px;
    font-weight: 700;
    color: #5E677E;
    line-height: 1.725;
`
);

const HowItWorks = (props) => {
  const { t } = useTranslation();
  return (
    <HowItWorksWrapper {...props}>
      <Container maxWidth="lg" sx={{ py: 5 }}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={6}>
            <MobileMockup
              src="/static/images/overview/MobileMockup.png"
              width="100%"
            />
          </Grid>
          <ContentGrid item xs={12} md={6} px={10}>
            <Title variant="h4">{t('... but how does it work?')}</Title>

            <StepWrapper>
              <StepNumber>1</StepNumber>
              <StepText
                sx={{ my: 2 }}
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
              >
                {t(
                  'Vendor snaps a photo of completed work and uploads it using the POP Mobile App'
                )}
                .
              </StepText>
            </StepWrapper>

            <StepWrapper>
              <StepNumber>2</StepNumber>
              <StepText
                sx={{ my: 2 }}
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
              >
                {t(
                  'Property Manager receives notification that work has been completed'
                )}
                .
              </StepText>
            </StepWrapper>

            <StepWrapper>
              <StepNumber>3</StepNumber>
              <StepText
                sx={{ my: 2 }}
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
              >
                {t(`There's no step 3. It's that simple`)}!
              </StepText>
            </StepWrapper>
          </ContentGrid>
        </Grid>
      </Container>
    </HowItWorksWrapper>
  );
};

export default HowItWorks;
