import { useState } from 'react';
import {
  Box,
  Container,
  Hidden,
  Card,
  Button,
  IconButton,
  Drawer
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ContentWrapper from 'src/components/ContentWrapper';
import { useTranslation } from 'react-i18next';
import { experimentalStyled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from 'src/components/Logo';
import Menu from './Menu';
import Hero from './Hero';
import TrustedBy from './TrustedBy';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Industries from './Industries';
import Footer from 'src/components/Footer';

const HeaderWrapper = experimentalStyled(Card)(
  ({ theme }) => `
    width: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 6;
    top: 0;
    height: ${theme.spacing(10)};
`
);

const OverviewWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    margin-top: ${theme.spacing(10)};
`
);

const SignInButton = experimentalStyled(Button)(
  ({ theme }) => `
    background: #fff;
    border: 1px solid #223354;
    color: #223354;
    height: 38px;
    ${theme.breakpoints.up('sm')} {
      margin-right: ${theme.spacing(2)}
    }
`
);

const ScheduleButton = experimentalStyled(Button)(
  ({ theme }) => `
    height: 38px;
`
);

const MainNav = experimentalStyled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
`
);

function Overview() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <OverviewWrapper>
      <ContentWrapper title="Partners Operating Platform">
        <HeaderWrapper>
          <Container maxWidth="lg">
            <MainNav display="flex" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                {false && (
                  <>
                    <IconButton
                      aria-label="menu"
                      size="small"
                      onClick={() => setOpen(true)}
                    >
                      <MenuIcon fontSize="inherit" />
                    </IconButton>
                    <Drawer
                      anchor="left"
                      open={open}
                      onClose={() => setOpen(false)}
                    >
                      <Menu />
                    </Drawer>
                  </>
                )}
                <Box sx={{ display: 'flex' }}>
                  <Logo />
                  <Hidden mdDown>
                    <Menu />
                  </Hidden>
                </Box>
                <Box>
                  <SignInButton
                    size="small"
                    component={RouterLink}
                    to="/login"
                    variant="contained"
                  >
                    {t('Sign In')}
                  </SignInButton>
                  <Hidden mdDown>
                    <ScheduleButton
                      size="small"
                      component="a"
                      href={process.env.REACT_APP_SCHEDULE_LINK}
                      variant="contained"
                    >
                      {t('Schedule Demo')}
                    </ScheduleButton>
                  </Hidden>
                </Box>
              </Box>
            </MainNav>
          </Container>
        </HeaderWrapper>
        <Hero />
        <TrustedBy />
        <Features id="features" />
        <HowItWorks id="how-it-works" />
        <Industries id="industries" />
      </ContentWrapper>
      <Footer />
    </OverviewWrapper>
  );
}

export default Overview;
