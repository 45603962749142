import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    property: null,
    workType: 'all',
    beforeDate: new Date(),
    afterDate: new Date(new Date().setDate(new Date().getDate() - 30))
  },
  queryFilters: []
};

const slice = createSlice({
  name: 'worklogs',
  initialState,
  reducers: {
    setFilters(state, action) {
      const { filterName, value } = action.payload;
      state.filters[filterName] = value;
      state.queryFilters = [
        state.filters.property?.length > 0
          ? {
              member: 'Properties.propertyUid',
              operator: 'equals',
              values: state.filters.property?.map((p) => p.uid)
            }
          : undefined,
        state.filters.workType !== 'all'
          ? {
              member: 'PropertyRecords.workType',
              operator: 'equals',
              values: [state.filters.workType]
            }
          : undefined
      ].filter(Boolean);
    },
    clearFilters(state, action) {
      state.filters = initialState.filters;
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isDrawerOpen = true;
      state.selectedRange = {
        start,
        end
      };
    }
  }
});

export const reducer = slice.reducer;

export const setFilters = (filterName, value) => async (dispatch) => {
  if (initialState.filters[filterName] === undefined) {
    throw new Error(`Invalid filter name: ${filterName}`);
  }
  dispatch(
    slice.actions.setFilters({
      filterName,
      value
    })
  );
};

export const clearFilters = () => (dispatch) => {
  dispatch(slice.actions.clearFilters());
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export default slice;
