const getLocalAccessToken = () => {
  return localStorage.getItem('accessToken');
};

const getLocalRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

const setLocalAccessToken = (token) => {
  localStorage.setItem('accessToken', token);
};

const setLocalRefreshToken = (token) => {
  localStorage.setItem('refreshToken', token);
};

export default {
  getLocalAccessToken,
  getLocalRefreshToken,
  setLocalAccessToken,
  setLocalRefreshToken
};
