import { createSlice } from '@reduxjs/toolkit';
import api from 'src/services/api';

const initialState = {
  properties: {
    init: false,
    loading: false,
    error: null,
    data: []
  },
  services: {
    init: false,
    loading: false,
    error: null,
    data: []
  }
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    getServices(state, action) {
      state.services.data = action.payload;
      state.services.init = true;
      state.services.loading = false;
    },
    setServicesLoading(state, action) {
      state.services.loading = true;
    },
    setServicesError(state, action) {
      state.services.error = action.payload;
    },
    getProperties(state, action) {
      state.properties.data = action.payload;
      state.properties.init = true;
      state.properties.loading = false;
    },
    setPropertiesLoading(state, action) {
      state.properties.loading = true;
    },
    setPropertiesError(state, action) {
      state.properties.error = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const { reset } = slice.actions;

export const getServices = () => async (dispatch, getState) => {
  const state = getState();
  if (state.user.services.init) return;
  try {
    dispatch(slice.actions.setServicesLoading());
    const response = await api.get('/webapp/services');
    dispatch(slice.actions.getServices(response.data.result));
  } catch (error) {
    dispatch(slice.actions.setServicesError(error));
  }
};

export const getProperties = () => async (dispatch, getState) => {
  const state = getState();
  if (state.user.properties.init) return;
  try {
    dispatch(slice.actions.setPropertiesLoading());
    const response = await api.get('/webapp/properties');
    dispatch(slice.actions.getProperties(response.data.result));
  } catch (error) {
    dispatch(slice.actions.setPropertiesError(error));
  }
};

export default slice;
