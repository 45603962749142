import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormHelperText, Select, MenuItem } from '@mui/material';

import api from 'src/services/api';

export default function SelectService({
  services: presetServices = null,
  inputErrorText,
  disableInputLabel = true,
  handleChange = (_e, value) => {
    console.log(value);
  },
  ...props
}) {
  const { t } = useTranslation();

  const [allServices, setAllServices] = useState([]);
  const getAllServices = useCallback(async () => {
    try {
      const response = await api.get('/webapp/services');
      setAllServices(response.data.result);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (!presetServices) {
      getAllServices();
    }

    return () => {
      setAllServices([]);
    };
  }, []);

  const services = presetServices || allServices;

  return (
    <FormControl
      fullWidth
      variant="outlined"
      placeholder={t('Select a work type')}
      error={!!inputErrorText}
    >
      <Select name="service" onChange={handleChange} {...props}>
        {services?.map((service) => (
          <MenuItem key={service.id} value={service.id}>
            {service.name}
          </MenuItem>
        ))}
      </Select>
      {!!inputErrorText && <FormHelperText>{inputErrorText}</FormHelperText>}
    </FormControl>
  );
}
