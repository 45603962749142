import { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import SidebarTopSection from './SidebarTopSection';

import { Box, Drawer, Hidden, Typography } from '@mui/material';

import { experimentalStyled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/Logo';
import AddWorkLog from './AddWorkLog';

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
        }
`
);

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      <Hidden mdDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Box sx={{ width: 52, ml: 1, mt: 1, mb: 3 }}>
                <Logo />
              </Box>
              <SidebarTopSection />
            </TopSection>
            <SidebarMenu />
            <AddWorkLog />
            <Box
              p={3}
              display={{ xs: 'block', md: 'flex' }}
              alignItems="center"
              textAlign={{ xs: 'center', md: 'left' }}
              justifyContent="space-between"
              sx={{ position: 'absolute', bottom: 0 }}
            >
              <Box>
                <Typography variant="subtitle1">
                  &copy; Copyright 2023 <br />
                  Partners Operating Platform, Inc.
                </Typography>
              </Box>
            </Box>
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <Box sx={{ width: 52, ml: 1, mt: 1, mb: 3 }}>
                  <Logo />
                </Box>
                <SidebarTopSection />
              </TopSection>
              <AddWorkLog />
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
