import axios from 'axios';

const axiosInt = axios.create();

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default axiosInt;
