import moment from 'moment';
import { WORK_TYPES } from 'src/components/SelectWorkType/constants';
export default function transformDataToSubmit(values) {
  const propertyUID = values.property?.uid;
  if (!propertyUID) throw new Error('DTE: Property is required');

  const serviceID = values.service;
  if (!serviceID) throw new Error('DTE: Service is required');

  const workType = WORK_TYPES[values.workType - 1]?.name;
  if (!workType) throw Error('DTE: No work type found');

  const comment = values.comment;
  if (!comment) throw new Error('DTE: Comments are required');

  const now = moment.utc();

  const createWorklogRequest = {
    // WorkScheduleEventUID: '218022456409',
    // VendorUID: 'VE8252090289', // This should be implicit from the request
    PropertyUID: propertyUID,
    ServiceID: serviceID,
    WorkType: workType,
    VendorComments: comment,
    DateTimeEntered: now,
    DateCompleted: now,

    WorkStatus: 'Completed',
    WorkDescription: 'Unknown'
  };

  const formData = new FormData();

  formData.append('createWorklogRequest', JSON.stringify(createWorklogRequest));
  // append all files
  values.photos.forEach((photo) => {
    formData.append('files', photo);
  });

  return formData;
}
