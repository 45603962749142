import {
  Box,
  Button,
  Avatar,
  Card,
  Container,
  Tooltip,
  Hidden,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import { experimentalStyled } from '@mui/material/styles';

const TypographyH1 = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(36)};
`
);

const TypographyH2 = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

const HeroWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    height: 500px;
    position: relative;
    background-image: url('/static/images/overview/hero.jpg');
    background-size: cover;
    background-position: bottom;
    padding-bottom: 25px;
    &::before {
      content: "";
      background: rgba(35, 50, 85, 0.70);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      height: auto;
    }
`
);

const HeroInner = experimentalStyled(Box)(
  ({ theme }) => `
    position: relative;
    color: #fff;
`
);

const VideoWrapper = experimentalStyled(Box)(
  () => `
    position: relative;

    .MuiContainer-root {
      position: relative;
      z-index: 5;
    }
`
);

const CardIframe = experimentalStyled(Card)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
    display: flex;
    position: relative;
`
);

const CardHeading = experimentalStyled(Card)(
  ({ theme }) => `
    position: absolute;
    background: ${theme.colors.gradients.blue3};
    opacity: .9;
    padding: ${theme.spacing(2, 3)};
    bottom: ${theme.spacing(4)};
    left: ${theme.spacing(4)};
    max-width: 480px;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: none;
    }

    .MuiTypography-h3 {
      color: ${theme.colors.alpha.trueWhite[100]};
    }
    
    .MuiTypography-h4 {
      font-weight: normal;
      color: ${theme.colors.alpha.trueWhite[70]};
    }
`
);

const MainButton = experimentalStyled(Button)(
  ({ theme }) => `
    background: white;
    color: #223354;
    &:hover {
      background: #D0D8E6
    }
`
);

function Hero() {
  const { t } = useTranslation();

  return (
    <HeroWrapper sx={{ textAlign: 'center', pt: { xs: 5, sm: 15 } }}>
      <HeroInner>
        <TypographyH1 sx={{ mb: 2 }} variant="h1">
          {t('Was it completed?')}
        </TypographyH1>
        <Container maxWidth="md" sx={{ mb: { xs: 5, sm: 10 } }}>
          <TypographyH2
            sx={{ lineHeight: 1.5, px: 4, pb: 4 }}
            variant="h4"
            fontWeight="normal"
          >
            {t('Spend less time worrying about vendor activities')}
          </TypographyH2>
          <MainButton
            component="a"
            href={process.env.REACT_APP_SCHEDULE_LINK}
            size="large"
            variant="contained"
          >
            {t('Schedule Demo')}
          </MainButton>
        </Container>
      </HeroInner>
    </HeroWrapper>
  );
}

export default Hero;
