import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import EventNoteTwoToneIcon from '@mui/icons-material/EventNoteTwoTone';
import DocumentScannerTwoToneIcon from '@mui/icons-material/DocumentScannerTwoTone';
import InboxIcon from '@mui/icons-material/InboxTwoTone';
import BusinessIcon from '@mui/icons-material/Business';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';

const menuItems = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: AnalyticsTwoToneIcon,
        link: '/app'
      },
      {
        name: 'My Properties',
        icon: BusinessIcon,
        link: '/app/properties'
      },
      {
        name: 'Inbox',
        icon: InboxIcon,
        link: '/app/inbox',
        roles: ['vendor']
      },
      {
        name: 'Property Schedules',
        icon: EventNoteTwoToneIcon,
        link: '/app/schedules'
      },
      {
        name: 'Property Contracts',
        icon: DocumentScannerTwoToneIcon,
        link: '/app/contracts'
      },
      {
        name: 'Completed Work Logs',
        icon: BeenhereOutlinedIcon,
        link: '/app/work-log'
      }
    ]
  }
  /* {
    heading: 'Extra Pages',
    items: [
      {
        name: 'Auth Pages',
        icon: VpnKeyTwoToneIcon,
        link: '/auth',
        items: [
          {
            name: 'Login',
            items: [
              {
                name: 'Basic',
                link: '/login-basic'
              },
              {
                name: 'Cover',
                link: '/login-cover'
              }
            ]
          },
          {
            name: 'Register',
            items: [
              {
                name: 'Basic',
                link: '/register-basic'
              },
              {
                name: 'Cover',
                link: '/register-cover'
              },
              {
                name: 'Wizard',
                link: '/register-wizard'
              }
            ]
          },
          {
            name: 'Recover Password',
            link: '/recover-password'
          }
        ]
      },
      {
        name: 'Status',
        icon: ErrorTwoToneIcon,
        link: '/status',
        items: [
          {
            name: 'Error 404',
            link: '/status/404'
          },
          {
            name: 'Error 500',
            link: '/status/500'
          },
          {
            name: 'Maintenance',
            link: '/status/maintenance'
          },
          {
            name: 'Coming Soon',
            link: '/status/coming-soon'
          }
        ]
      }
    ]
  } */
];

export default menuItems;
