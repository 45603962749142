import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import Overview from 'src/views/overview';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';

export const renderRoutes = (routes) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: '/app',
    guard: Authenticated,
    layout: AccentSidebarLayout,
    routes: [
      {
        exact: true,
        path: '/app/',
        component: lazy(() => import('src/views/Dashboard'))
      },
      {
        exact: true,
        path: '/app/properties',
        component: lazy(() => import('src/views/Properties'))
      },
      {
        exact: true,
        path: '/app/schedules',
        component: lazy(() => import('src/views/Schedules'))
      },
      {
        exact: true,
        path: '/app/contracts',
        component: lazy(() => import('src/views/ContractSearch'))
      },
      {
        exact: true,
        path: '/app/work-log',
        component: lazy(() => import('src/views/WorkLog'))
      },
      {
        path: '/app/work-log/:guid',
        component: lazy(() => import('src/views/WorkLog/single'))
      },
      {
        path: '/app/account/:tabSelection',
        component: lazy(() => import('src/views/Account'))
      },
      {
        exact: true,
        path: [
          '/app/inbox/tag/:labelTag/:mailboxCategory?',
          '/app/inbox/:categoryTag/:mailboxCategory?'
        ],
        component: lazy(() => import('src/views/Inbox'))
      },
      {
        exact: true,
        path: '/app/inbox',
        component: () => <Redirect to="/app/inbox/unscheduled" />
      },
      {
        exact: true,
        path: '/app/analytics',
        component: lazy(() => import('src/views/Dashboard'))
      }
    ]
  },
  {
    exact: true,
    guard: Guest,
    path: '/overview',
    component: () => <Redirect to="/" />
  },
  {
    exact: true,
    guard: Guest,
    path: '/login',
    component: lazy(() => import('src/views/Auth/Login/Cover'))
  },
  {
    exact: true,
    path: '/recover-password',
    component: lazy(() => import('src/views/Auth/RecoverPassword'))
  },
  {
    exact: true,
    path: '/set-new-password',
    component: lazy(() => import('src/views/Auth/SetNewPassword'))
  },
  {
    exact: true,
    path: '/status/404',
    component: lazy(() => import('src/views/Status/Status404'))
  },
  {
    exact: true,
    path: '/status/500',
    component: lazy(() => import('src/views/Status/Status500'))
  },
  {
    exact: true,
    path: '/status/coming-soon',
    component: lazy(() => import('src/views/Status/ComingSoon'))
  },
  {
    exact: true,
    path: '/status/maintenance',
    component: lazy(() => import('src/views/Status/Maintenance'))
  },
  {
    path: '*',
    guard: Guest,
    layout: BaseLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: Overview
      },
      {
        component: () => <Redirect to="/" />
      }
    ]
  }
];

export default routes;
