import { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import api from 'src/services/api';
import TokenService from 'src/services/tokens';
import { useDispatch } from 'src/store';
import { reset } from 'src/slices/user';
import appConfig from 'src/app.config';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isRefreshing: false,
  user: null,
  cubejsApi: null
};

const cubeConfig = {
  apiUrl: appConfig.cubeApi
};

const isTokenExpired = (accessToken) => {
  if (!accessToken) {
    return true;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp < currentTime;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, accessToken, cubejsApi } = action.payload;
      TokenService.setLocalAccessToken(accessToken);
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        cubejsApi
      };
    }
    case 'REFRESH_ACCESS_START': {
      return {
        ...state,
        isRefreshing: true
      };
    }
    case 'REFRESH_ACCESS_SUCCESS': {
      const { accessToken, cubejsApi } = action.payload;
      TokenService.setLocalAccessToken(accessToken);
      return {
        ...state,
        isRefreshing: false,
        cubejsApi
      };
    }
    case 'LOGIN': {
      const { user, accessToken, refreshToken, cubejsApi } = action.payload;
      TokenService.setLocalAccessToken(accessToken);
      TokenService.setLocalRefreshToken(refreshToken);
      return {
        ...state,
        isAuthenticated: true,
        user,
        cubejsApi
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        cubejsApi: null
      };
    }
    case 'REGISTER': {
      const { user, accessToken, cubejsApi } = action.payload;
      TokenService.setLocalAccessToken(accessToken);
      return {
        ...state,
        isAuthenticated: true,
        user,
        cubejsApi
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  refreshAccessToken: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const reduxDispatch = useDispatch();

  const login = async (email, password) => {
    const response = await api.post(`/authenticate/GetClaims`, {
      username: email,
      password
    });

    const {
      jwtToken: accessToken,
      refreshToken,
      user
    } = response.data?.result || {};

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          name: user.fullName,
          role: user.accountType
        },
        accessToken,
        refreshToken,
        cubejsApi: cubejs(accessToken, cubeConfig)
      }
    });
  };

  const logout = async () => {
    dispatch({ type: 'LOGOUT' });
    reduxDispatch(reset());
  };

  const register = async (email, name, password) => {
    const response = await api.post('/webapp/account/register', {
      email,
      name,
      password
    });
    const { jwtToken: accessToken, user } = response.data.result;

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        accessToken,
        cubejsApi: cubejs(accessToken, cubeConfig)
      }
    });
  };

  const recover = async (email) => {
    const response = await api.post(
      '/authenticate/forgot-password/request-otp',
      null,
      {
        params: { email }
      }
    );
    const { result } = response.data;
    return result;
  };

  const validate = async (email, otp, newPassword) => {
    const response = await api.post(
      '/authenticate/forgot-password/validate-otp',
      { email, otp, newPassword }
    );
    const { result } = response.data;
    return result;
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = TokenService.getLocalAccessToken();
        if (accessToken) {
          if (isTokenExpired(accessToken)) await refreshAccessToken();
          const response = await api.get('/webapp/account/personal');
          const user = response.data.result;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                name: user.username || user.userName
              },
              cubejsApi: cubejs(accessToken, cubeConfig),
              accessToken
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              cubejsApi: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            cubejsApi: null
          }
        });
      }
    };

    initialise();
  }, []);

  const refreshAccessToken = async () => {
    if (state.isRefreshing) return; // only allow one refresh at a time
    try {
      dispatch({ type: 'REFRESH_ACCESS_START' });
      const response = await api.post('/authenticate/refresh-token', null, {
        params: {
          token: TokenService.getLocalRefreshToken()
        }
      });
      const { jwtToken: newAccessToken, refreshToken: newRefreshToken } =
        response.data.result;
      dispatch({
        type: 'REFRESH_ACCESS_SUCCESS',
        payload: {
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
          cubejsApi: cubejs(newAccessToken, cubeConfig)
        }
      });
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  if (!state.isInitialised) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        recover,
        validate,
        refreshAccessToken
      }}
    >
      <CubeProvider cubejsApi={state.cubejsApi}>{children}</CubeProvider>
    </AuthContext.Provider>
  );
};

export default AuthContext;
