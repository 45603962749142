import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Alert,
  Box,
  Divider,
  ListItem,
  List,
  ListItemText,
  Typography,
  IconButton
} from '@mui/material';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import { experimentalStyled } from '@mui/material/styles';
import { formatBytes } from 'src/utils/formatBytes';
import { DeleteForever } from '@mui/icons-material';

const BoxUploadWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    cursor: pointer;
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(3)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      border-color: ${theme.colors.primary.main};
    }
`
);

const AvatarWrapper = experimentalStyled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.primary.lighter};
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarSuccess = experimentalStyled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = experimentalStyled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

export default function FileUpload({
  files = [],
  error = false,
  helperText = 'An error has occurred while uploading.',
  handleFileUpload = () => {},
  handleRemoveFile = () => {}
}) {
  const { t } = useTranslation();

  const {
    inputRef,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: handleFileUpload
  });

  return <>
    <BoxUploadWrapper
      {...getRootProps()}
      sx={(theme) =>
        !error
          ? null
          : {
              borderColor: theme.colors.error.main
            }
      }
    >
      <input ref={inputRef} {...getInputProps()} />
      {isDragAccept && (
        <>
          <AvatarSuccess variant="rounded">
            <CheckTwoToneIcon />
          </AvatarSuccess>
          <Typography sx={{ mt: 2 }}>
            {t('Drop the files to start uploading')}
          </Typography>
        </>
      )}
      {isDragReject && (
        <>
          <AvatarDanger variant="rounded">
            <CloseTwoToneIcon />
          </AvatarDanger>
          <Typography sx={{ mt: 2 }}>
            {t('You cannot upload these file types')}
          </Typography>
        </>
      )}
      {!isDragActive && (
        <>
          <AvatarWrapper variant="rounded">
            <CloudUploadTwoToneIcon />
          </AvatarWrapper>
          <Typography sx={{ mt: 2 }}>
            {t('Drag & drop files here')}
          </Typography>
        </>
      )}
    </BoxUploadWrapper>
    {error && (
      <Alert sx={{ py: 0, mt: 2 }} severity="error">
        {helperText}
      </Alert>
    )}
    {files.length > 0 && (
      <>
        <Alert sx={{ py: 0, mt: 2 }} severity="success">
          {t('You have ')} <b>{files.length}</b>{' '}
          {files.length > 1 ? t('files') : t('file')}
          {t(' ready for upload')}!
        </Alert>
        <Divider sx={{ mt: 2 }} />
        <List disablePadding component="div">
          {files.map((file, index) => (
            <ListItem disableGutters component="div" key={index}>
              <Avatar src={file.preview} variant="rounded" sx={{ mr: 1 }} />
              <ListItemText primary={file.name} />
              <b>{formatBytes(file.size)}</b>
              <IconButton onClick={() => handleRemoveFile(index)} size="large">
                <DeleteForever />
              </IconButton>
              <Divider />
            </ListItem>
          ))}
        </List>
        <Divider sx={{ mx: 2 }} />
      </>
    )}
  </>;
}
