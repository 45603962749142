import { useTranslation } from 'react-i18next';
import { Grid, Box, Container, Typography } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';

const BoxHighlights = experimentalStyled(Box)(
  () => `
    position: relative;
    z-index: 5;
`
);

const TitleBox = experimentalStyled(Box)(
  () => `
    background: white;
    height: 100%;
    padding: 40px;
    border-radius: 10px;
    border: 1px solid #E3E3E3;
`
);

const TitleHeading = experimentalStyled(Typography)(
  () => `
    color: #223354;
    font-size: 36px;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 26px;
    margin-top: 14px;
`
);

const TitleSubHeading = experimentalStyled(Typography)(
  () => `
    font-size: 21px;
    color: #636F86;
`
);

const IndustryBox = experimentalStyled(Box)(
  () => `
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    padding-top: 100%;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
`
);

const IndustryBoxOverlay = experimentalStyled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.4);

    display: flex;
    align-items: center;
    justify-content: center;
`
);

const IndustryLabel = experimentalStyled(Box)(
  () => `
    text-transform: capitalize;
    color: #fff;
    font-size: 33px;
    font-weight: 700;
    text-shadow: 0px 2px 8px rgba(0,0,0,0.50);
`
);

const industries = [
  {
    name: 'government',
    imageUrl: '/static/images/overview/industries/government.jpg'
  },
  {
    name: 'commercial',
    imageUrl: '/static/images/overview/industries/commercial.jpg'
  },
  {
    name: 'industrial',
    imageUrl: '/static/images/overview/industries/industrial.jpg'
  },
  {
    name: 'retail',
    imageUrl: '/static/images/overview/industries/retail.jpg'
  },
  {
    name: 'multi-family',
    imageUrl: '/static/images/overview/industries/apartment.jpg'
  },
  {
    name: 'public storage',
    imageUrl: '/static/images/overview/industries/public_storage.jpg'
  },
  {
    name: 'schools',
    imageUrl: '/static/images/overview/industries/school.jpg'
  }
];

const Industries = (props) => {
  return (
    <BoxHighlights {...props}>
      <Container maxWidth="lg" sx={{ pt: { xs: 2, md: 5 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TitleBox>
              <TitleHeading>
                Industries <br /> we support
              </TitleHeading>
              <TitleSubHeading>
                We provide a solution to fit every asset class.
              </TitleSubHeading>
            </TitleBox>
          </Grid>
          {industries.map((industry) => (
            <Grid item xs={12} sm={6} md={3}>
              <IndustryBox
                sx={{
                  backgroundImage: `url(${industry.imageUrl})`
                }}
              >
                <IndustryBoxOverlay>
                  <IndustryLabel>{industry.name}</IndustryLabel>
                </IndustryBoxOverlay>
              </IndustryBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </BoxHighlights>
  );
};

export default Industries;
