import pdfjsPackageJson from 'pdfjs-dist/package.json';

function isRequired(value, message) {
  if (value) return value;
  else throw new Error(message || 'Missing required parameter');
}

function setupConfig() {
  // .env variables injected at build time & fixed vars
  let config = {
    env: isRequired(process.env.REACT_APP_ENV, 'App environment not set'),
    version: process.env.REACT_APP_VERSION || 'unregistered',
    restApi: isRequired(process.env.REACT_APP_API_URL, 'API URL not set'),
    cubeApi: isRequired(
      process.env.REACT_APP_CUBE_JS_URL,
      'Cube API URL not set'
    ),
    ikPublicKey: 'public_d1Z6ryBQLsUas7Ahr5zo5oahWXE=',
    ikEndpoint: 'https://ik.imagekit.io/pop2',
    lightGalleryLicenseKey: '78589A62-7DCA41A4-B862FB41-888B5D6C',
    pdfjsVersion: isRequired(pdfjsPackageJson.version, 'PDFJS version not set')
  };

  // env-specific variables
  switch (config.env) {
    case 'production':
      config.ikContainers = {
        properties: 'production-properties',
        worklog: 'production-worklog',
        attachments: 'production-attachments'
      };
      break;
    case 'staging':
      config.ikContainers = {
        properties: 'staging-properties',
        worklog: 'staging-worklog',
        attachments: 'staging-attachments'
      };
      break;
    default:
      console.error('Unknown environment: ', config.env);
      break;
  }

  // colorfully print config
  console.info(
    '%cPOP Application Config',
    'background: #222; color: #bada55; font-size: 16px; padding: 4px 8px; border-radius: 4px;',
    config
  );

  return config;
}

export default setupConfig();
