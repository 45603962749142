import PropTypes from 'prop-types';
import { experimentalStyled } from '@mui/material/styles';
import { Box } from '@mui/material';

import Sidebar from './Sidebar';

const FullPageContainer = experimentalStyled(Box)(
  ({ theme }) => `
  display: grid;
  grid-template-columns: ${theme.sidebar.width} 1fr;
  grid-auto-flow: column;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  & > *:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  & > *:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    grid-template-columns: 1fr;
    & > *:nth-child(2) {
      grid-column: 1;
    }
  }

  }
`
);

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    flex: 1 1 auto;
    display: flex;
    height: 100%;
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
    margin-top: ${theme.header.height};
    flex: 1 1 auto;
    overflow: auto;
`
);

const AccentSidebarLayout = ({ children }) => {
  return (
    <FullPageContainer>
      <Sidebar />
      <MainWrapper>
        {/* <Header /> */}
        <MainContent>{children}</MainContent>
      </MainWrapper>
    </FullPageContainer>
  );
};

AccentSidebarLayout.propTypes = {
  children: PropTypes.node
};

export default AccentSidebarLayout;
