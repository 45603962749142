import { Box, Container } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';

const BoxHighlights = experimentalStyled(Box)(
  () => `
    position: relative;
    z-index: 5;
`
);

const SectionTitle = experimentalStyled(Box)(
  () => `
    text-align: center;
    color: #636F86;
    font-weight: 700;
    font-size: 18px;
`
);

const TrustedBy = (props) => {
  return (
    <BoxHighlights {...props}>
      <Container maxWidth="lg" sx={{ pt: { xs: 2, md: 5 } }}>
        <SectionTitle>Trusted By</SectionTitle>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            mb: { xs: 5, md: 10 },
            flexWrap: { xs: 'wrap', md: 'nowrap' }
          }}
        >
          <Box display="flex" alignItems="center" p={3} mx={3}>
            <img src="/static/images/overview/trusted-by-companies/remedy_medical_logo_mono.svg" />
          </Box>
          <Box display="flex" alignItems="center" p={3} mx={3}>
            <img src="/static/images/overview/trusted-by-companies/cushman_wakefield_logo_mono.svg" />
          </Box>
          <Box display="flex" alignItems="center" p={3} mx={3}>
            <img src="/static/images/overview/trusted-by-companies/facebook_logo_mono.svg" />
          </Box>
          <Box display="flex" alignItems="center" p={3} mx={3}>
            <img src="/static/images/overview/trusted-by-companies/poe_cronk_logo_mono.svg" />
          </Box>
        </Box>
      </Container>
    </BoxHighlights>
  );
};

export default TrustedBy;
