import { useTranslation } from 'react-i18next';
import { WORK_TYPES } from './constants';

import {
  FormControl,
  FormHelperText,
  Select,
  MenuItem
} from '@mui/material';

export default function SelectWorkType({
  inputErrorText,
  disableInputLabel = true,
  handleChange = (_e, value) => {
    console.log(value);
  },
  ...props
}) {
  const { t } = useTranslation();
  return (
    <FormControl
      fullWidth
      variant="outlined"
      placeholder={t('Select a work type')}
      error={!!inputErrorText}
    >
      <Select name="workType" onChange={handleChange} {...props}>
        {WORK_TYPES.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {!!inputErrorText && <FormHelperText>{inputErrorText}</FormHelperText>}
    </FormControl>
  );
}
