import { useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import api from 'src/services/api';

import { experimentalStyled } from '@mui/material/styles';

import wait from 'src/utils/wait';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Zoom,
  Typography,
  TextField,
  CircularProgress,
  Button,
  Select,
  MenuItem,
  Alert
} from '@mui/material';
import { useSnackbar } from 'notistack';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import FileUpload from 'src/components/FileUpload';
import SelectProperty from 'src/components/SelectProperty';
import SelectWorkType from 'src/components/SelectWorkType';
import SelectService from 'src/components/SelectService';

import transformDataToSubmit from './transformDataToSubmit';

const ButtonContainer = experimentalStyled('div')(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
`
);

const AddButton = experimentalStyled(Button)(
  ({ theme }) => `
  width: 190px;
  height: 50px;
  margin-top: 18px;
  `
);

export default function AddWorkLog() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [services, setServices] = useState([]);
  const getServices = useCallback(async (propertyId, setFieldValue) => {
    if (!propertyId) return false;
    try {
      const response = await api.get('/webapp/services', {
        params: { ...(propertyId && { property_UID: propertyId }) }
      });
      const servicesFromServer = response.data.result;
      setServices(servicesFromServer);

      if (servicesFromServer.length <= 1) {
        if (typeof setFieldValue !== 'function') return false;
        setFieldValue('service', servicesFromServer[0].id);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    // Cleanup on unmount
    return () => setServices([]);
  }, []);

  const isServiceRequired = services.length > 1; // TODO: Set this dynamically

  function handleCreateWorkLogOpen() {
    setOpen(true);
  }

  const handleCreateWorkLogClose = () => {
    setOpen(false);
  };

  const handleCreateWorkLogSuccess = () => {
    enqueueSnackbar(t('A new work log has been created successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    setOpen(false);
  };

  return <>
    <ButtonContainer>
      <AddButton
        onClick={handleCreateWorkLogOpen}
        variant="contained"
        startIcon={<AddTwoToneIcon fontSize="small" />}
      >
        New Work Log
      </AddButton>
    </ButtonContainer>
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCreateWorkLogClose}
    >
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t('Create new work log')}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          property: null,
          service: null,
          workType: null,
          comment: '',
          photos: [],
          submit: null
        }}
        validationSchema={Yup.object().shape({
          isServiceRequired: Yup.boolean(),
          property: Yup.object()
            .typeError(t('Property selection is required'))
            .required(t('Property selection is required')),
          service: Yup.number().required('Service is required zzz'),
          // work type enum number only 1 to 4
          workType: Yup.number()
            .min(1, t('Work type selection is invalid'))
            .max(4, t('Work type selection is invalid'))
            .required(t('Work type selection is required')),
          comment: Yup.string()
            .max(255)
            .required(t('The comment field is required')),
          photos: Yup.array()
            .min(1, t('At least 1 photo is required'))
            .max(10, t('You can only upload a maximum of 10 photos'))
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const data = transformDataToSubmit(values);

            await api.post('/vendor/client/property/create-worklog', data, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });

            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            handleCreateWorkLogSuccess();
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values
        }) => {
          function handlePropertyChange(_event, selectedProperty) {
            setFieldValue('property', selectedProperty);
            getServices(selectedProperty?.uid, setFieldValue);
          }

          function handleWorkTypeChange(event) {
            const selectedWorkTypeValue = event.target.value;
            setFieldValue('workType', selectedWorkTypeValue);
          }

          function handleServiceChange(event) {
            const selectedServiceValue = event.target.value;
            setFieldValue('service', selectedServiceValue);
          }

          function handleFileUpload(newFiles) {
            const existingFiles = values.photos;
            // Dropzone uploads will come as an array of objects
            const allFiles = [].concat(
              existingFiles,
              newFiles.map((file) => {
                file.preview = URL.createObjectURL(file);
                return file;
              })
            );
            // TODO: Remove all duplicates and return the new array (WIP)
            // Problem quickly comparing objects with ===
            const uniqueFiles = allFiles.filter(
              (file, index) => allFiles.indexOf(file) === index
            );
            setFieldValue('photos', uniqueFiles);
          }

          function handleRemoveFile(index) {
            const existingFiles = values.photos;
            const newFiles = existingFiles.filter((file, i) => i !== index);
            setFieldValue('photos', newFiles);
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers sx={{ p: 3 }}>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    justifyContent="flex-end"
                    textAlign={{ sm: 'right' }}
                  >
                    <Box
                      pr={3}
                      sx={{ pt: 2, pb: { xs: 1, md: 0 } }}
                      alignSelf="center"
                    >
                      <b>{t('Property')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: 3
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <SelectProperty
                      name="property"
                      value={values.property}
                      inputErrorText={touched.property && errors.property}
                      handleChange={handlePropertyChange}
                    />
                  </Grid>
                  {isServiceRequired && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        justifyContent="flex-end"
                        textAlign={{ sm: 'right' }}
                      >
                        <Box
                          pr={3}
                          sx={{ pt: 2, pb: { xs: 1, md: 0 } }}
                          alignSelf="center"
                        >
                          <b>{t('Service')}:</b>
                        </Box>
                      </Grid>
                      <Grid
                        sx={{
                          mb: 3
                        }}
                        item
                        xs={12}
                        sm={8}
                        md={9}
                      >
                        <SelectService
                          name="service"
                          value={values.service || ''}
                          inputErrorText={touched.service && errors.service}
                          handleChange={handleServiceChange}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    justifyContent="flex-end"
                    textAlign={{ sm: 'right' }}
                  >
                    <Box
                      pr={3}
                      sx={{ pt: 2, pb: { xs: 1, md: 0 } }}
                      alignSelf="center"
                    >
                      <b>{t('Work Type')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: 3
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <SelectWorkType
                      handleChange={handleWorkTypeChange}
                      value={values.workType || ''}
                      inputErrorText={touched.workType && errors.workType}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    justifyContent="flex-end"
                    textAlign={{ sm: 'right' }}
                  >
                    <Box
                      pr={3}
                      sx={{ pt: 2, pb: { xs: 1, md: 0 } }}
                      alignSelf="center"
                    >
                      <b>{t('Comments')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: 3
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="comment"
                      placeholder={t('Comments here...')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.comment}
                      error={Boolean(touched.comment && errors.comment)}
                      helperText={touched.comment && errors.comment}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    textAlign={{ sm: 'right' }}
                  >
                    <Box pr={3} sx={{ pb: { xs: 1, md: 0 } }}>
                      <b>{t('Upload files')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: 3
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <FileUpload
                      handleFileUpload={handleFileUpload}
                      handleRemoveFile={handleRemoveFile}
                      files={values.photos}
                      error={Boolean(touched.photos && errors.photos)}
                      helperText={touched.photos && errors.photos}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    textAlign={{ sm: 'right' }}
                  ></Grid>
                  <Grid item xs={12} sm={4} md={5}>
                    {touched.submit && errors.submit && (
                      <Alert severity="error">{errors.submit}</Alert>
                    )}
                  </Grid>
                  <Grid
                    sx={{
                      mb: 3,
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                    item
                    xs={12}
                    sm={4}
                    md={4}
                  >
                    <Button
                      color="secondary"
                      size="large"
                      variant="outlined"
                      onClick={handleCreateWorkLogClose}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      sx={{ ml: 2 }}
                      type="submit"
                      startIcon={
                        isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={Boolean(errors.submit) || isSubmitting}
                      variant="contained"
                      size="large"
                    >
                      {t('Submit work log')}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  </>;
}
