import appConfig from 'src/app.config';

const { ikEndpoint, ikContainers } = appConfig;

const fallbacks = {
  properties: '/static/images/defaults/building-wide.svg'
};

export function makeUrlEndpoint(containerKey) {
  if (ikContainers[containerKey]) {
    return ikEndpoint + '/' + ikContainers[containerKey];
  }
  if (containerKey === undefined) {
    console.error('containerKey is undefined');
    return ikEndpoint;
  }
  console.error('Invalid containerKey: ', containerKey);
  return ikEndpoint;
}

// Ex 1: https://pop2prodstorage.blob.core.windows.net/properties/525cc614e302/14020ThunderboltPlaceImage-20220219155835.jpg

export function transformImagePath({
  path,
  containerKey,
  transformation,
  fullUrl = false,
  splitContainer = false
}) {
  if (!path && fullUrl) return fallbacks[containerKey];

  const regex = new RegExp(`.*\/${containerKey}\/`);
  const containerlessPath = path?.replace(regex, '');
  const newContainer = ikContainers[containerKey] || '';
  const containerlessPathClean =
    containerlessPath === undefined ? '' : containerlessPath;
  const transformationClean =
    transformation === undefined ? '' : transformation;

  if (splitContainer) {
    return {
      urlEndpoint: ikEndpoint + '/' + newContainer,
      container: newContainer,
      path: containerlessPathClean,
      fallback: fallbacks[containerKey]
    };
  }

  if (fullUrl) {
    let newPath = new URL(
      `pop2/${newContainer}/${containerlessPathClean}?${transformationClean}`,
      ikEndpoint
    );
    return newPath.toString();
  } else {
    let newPath = `${newContainer}/${containerlessPathClean}?${transformationClean}`;
    return newPath;
  }
}
